var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pageTitle)+" ")]),(_vm.config.bo_use_category == 1)?_c('v-sheet',{staticClass:"ml-4",attrs:{"width":"150"}},[_c('cate-select',{attrs:{"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}}})],1):_vm._e(),_c('search-field',{attrs:{"items":_vm.searchItems,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}}}),_c('v-spacer'),_c('v-btn',{attrs:{"to":("/board/" + _vm.table + "?act=write"),"color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" 새글 작성 ")],1)],1),_c('v-data-table',{staticClass:"fixedTable",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.getNo(index))+" ")]}},{key:"item.wr_title",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-none px-0 justify-start basic-title",attrs:{"to":("/board/" + _vm.table + "/" + (item.wr_id)),"block":"","plain":""}},[_c('div',{staticClass:"d-flex justify-start align-center"},[(item.wr_dep > 0)?_c('v-icon',{style:({ 'padding-left': (((item.wr_dep - 1) * 16) + "px") })},[_vm._v(" mdi-subdirectory-arrow-right ")]):_vm._e(),_c('div',{staticClass:"text-truncate",style:({
              'max-width': ("calc(100% - 20px - " + (item.wr_dep > 0 ? (item.wr_dep - 1) * 16 + 24 : 0) + "px)"),
            })},[_vm._v(" "+_vm._s(item.wr_title)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"px-1 ml-1",attrs:{"x-small":"","label":"","color":"green"}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.replys)+" ")])]}}],null,true)},[_c('span',[_vm._v("댓글수 : "+_vm._s(item.replys))])])],1)])]}},{key:"item.wr_createat",fn:function(ref){
            var item = ref.item;
return [_c('display-time',{attrs:{"time":item.wr_createat}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }